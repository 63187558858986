<template>
  <div style="width: 100%; height: 100%">
    <!-- <div
      class="d-flex justify-content-center align-items-center"
      v-if="pedidos.length > 0"
    >
      <b-icon-eye class="mr-2" />
      <label class="d-flex">
        Itens
        <b-checkbox
          class="ml-2"
          switch
          v-model="tipoVisu"
          value="Pedidos"
          unchecked-value="Itens"
          :options="['Itens', 'Pedidos']"
        >
          Por Comanda
        </b-checkbox>
      </label>
    </div> -->

    <div
      style=""
      class="containerPedidos scrollPersonalizadoWH"
      v-if="tipoVisu == 'Itens'"
    >
      <b
        class="text-center"
        v-if="pedidos.length > 0"
        >Itens {{ mesaVisu ? " da mesa " + mesaVisu : " de Todas as Mesas" }}</b
      >
      <hr />
      <div
        v-if="!pedidos || pedidos.length <= 0"
        class="p-2 text-center"
      >
        Nenhum item adicionado ainda.
      </div>
      <div
        v-for="it in pedidos"
        :key="`it_${it.cnt}`"
        class="item"
        :class="it.excluido === 1 ? 'itemCancelado' : ''"
        @click="$emit('openPedidoEdit', it)"
      >
        <div class="imgs">
          <div
            :style="`background-image: url(${
              it.img ||
              'https://www.syncros.com/_ui/responsive/common/images/no-product-image-available.png'
            })`"
          ></div>
          <div
            v-if="it.img2"
            :style="`background-image: url(${
              it.img2 ||
              'https://www.syncros.com/_ui/responsive/common/images/no-product-image-available.png'
            })`"
          ></div>
        </div>
        <div
          style="flex: 1"
          class="pl-2"
        >
          {{ it.quantidade }} x {{ it.prod }}
          {{ it.prod2 && it.prod2 != "" ? " / " + it.prod2 : "" }}
          {{ it.borda && it.borda != "" ? " - Borda " + it.borda : "" }}
          <div
            v-if="it.excluido === 1"
            style="text-decoration: none !important"
          >
            <b-icon-trash /> ITEM CANECLADO
          </div>
          <div>
            <b><i class="fa fa-barcode mr-1"></i>{{ it.comanda }}</b>
          </div>
        </div>
        <div
          class=""
          style="align-self: self-start; min-width: 60px; text-align: right"
        >
          {{ it.vl_total | currencyMask }}
        </div>
      </div>
      <div v-if="pedidos.length > 0">
        <small>(clique no item para abrir/editar o pedido)</small>
      </div>
      
      <div
        :class="`btPlus text-center`"
        @click="addPedido()"
        v-if="(comandaSel && !comandaSel.dt_fechada) || !mesaObj.dt_fechada"
      >
        <i class="fa fa-plus" />
      </div>
    </div>
    <div
      style=""
      class="containerPedidos scrollPersonalizadoWH"
      v-if="tipoVisu == 'Pedidos'"
    >
      <mesa-new-pedido-resumo
        :mesaObj="mesaObj"
        :mesaVisu="mesaVisu"
        :getMesaNome="getMesaNome"
        :comanda="comandaSel"
        @addPgto="addPgto"
        @removerTaxa="removeTaxa()"
        :showFechar="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
        :showButtons="(comandaSel && !comandaSel.dt_fechada) || !comandaSel"
        @fecharMesa="fecharMesa"
        @gotoPG="gotoPG"
        @addItem="addPedido"
      />

      <div
        v-if="!pedidosPorPedido || pedidosPorPedido.length <= 0"
        class="p-2 text-center"
      >
        Nenhum pedido adicionado ainda.
      </div>
      <div v-if="pedidos.length > 0">
        <small>(clique no item para abrir/editar o pedido)</small>
      </div>
      <div
        v-for="p in pedidosPorPedido"
        :key="`ped_${p.qt}`"
        class="pedido"
        :class="p.itens[0].excluido === 1 ? '' : ''"
      >
        <b-card>
          <b-card-title>
            <div class="d-flex justify-content-between">
              <div>
                <i class="fa fa-barcode mr-1" />
                {{ ` ${getComandaNome(p.comanda, 0) || ""}` }}
                <br v-if="$store.state.isMobile" />
                <span
                  v-if="
                    getComandaNome(p.comanda, 1) &&
                    getComandaNome(p.comanda, 1).trim() != ''
                  "
                  class="ml-2"
                  style="font-size: 10px"
                >
                  <i class="fa fa-user mr-1" />
                  {{ getComandaNome(p.comanda, 1) || "" }}
                  <i class="fa fa-phone ml-2 mr-1" />
                  {{ getComandaNome(p.comanda, 2) || "" }}
                </span>
              </div>
              <div>
                <b-btn @click="$emit('trocarMesaComanda', p)" v-if="(comandaSel && !comandaSel.dt_fechada) || !comandaSel">
                  <i class="fas fa-exchange-alt"></i>
                </b-btn>
              </div>
            </div>
          </b-card-title>
          <b-card-sub-title
            class="mb-4"
            style="font-size: 12px"
          >
            <b-icon-person
              class="mr-1"
              v-if="p.usuario"
            />{{ `${p.usuario || ""} ` }} <b-icon-clock class="mr-1" />
            {{ p.data | moment("DD/MM/YYYY HH:mm") }}
          </b-card-sub-title>
          <div
            v-for="it in p.itens"
            :key="`it_${p.qt}_${it.cnt}`"
            class="item"
            :class="it.excluido === 1 ? 'pedidoCancelado' : ''"
            @click="openItem(it)"
          >
            <div>
              - {{ it.quantidade }} x {{ it.prod }}
              {{ it.prod2 && it.prod2 != "" ? " / " + it.prod2 : "" }}
              {{ it.borda && it.borda != "" ? " - Borda " + it.borda : "" }}
              <span
                v-if="it.excluido === 1"
                style="color: red !important"
                class="ml-3"
              >
                <b-icon-trash /> EXCLUIDO
              </span>
              <div
                style="font-size: 8px"
                class="pl-4"
              >
                <i class="fa fa-headset mr-1" /> {{ it.usuario }}
                <b-icon-clock class="ml-1" /> {{ it.data | moment("DD/MM/YYYY HH:mm") }}
              </div>
            </div>
            <div
              class=""
              style="align-self: self-end; min-width: 60px; text-align: right"
            >
              <b-btn
                v-if="it.prod && it.prod.toUpperCase().indexOf('ROD') >= 0"
                size="sm"
                v-b-tooltip.hover
                title="IMPRIMIR COMPROVANTE SAIDA"
                class="mr-2"
                variant="info"
                @click.stop="imprimeComprovanteSaida(it)"
              >
                <b-icon-check />
              </b-btn>
              <small class="mr-2"
                ><small
                  >({{ it.quantidade }} x
                  {{ it.vl_unitario | currency }})</small
                ></small
              >
              {{ it.vl_total | currencyMask }}
            </div>
          </div>
          <div class="mt-4">
            <b
              >Total
              <div class="float-right">
                {{ p.vl | currencyMask }}
              </div>
            </b>
          </div>
        </b-card>
      </div>
      
      <div
        :class="`btPlus text-center`"
        @click="addPedido()"
        v-if="(comandaSel && (!comandaSel.dt_fechada)) || !comandaSel && !mesaObj.dt_fechada"
      >
        <i class="fa fa-plus" />
      </div>
    </div>
  </div>
</template>

<script>
import MesaNewPedidoResumo from "./MesaNewPedidoResumo.vue";
export default {
  components: { MesaNewPedidoResumo },
  props: {
    mesaObj: Object,
    mesaVisu: Number,
    getMesaNome: Function,
    addPedido: Function,
    comandaSel: Object,
  },
  mounted() {
    this.calc();
    // console.log("ms", this.mesaObj);
  },
  data() {
    return {
      // pedidosPorPedido: [],
      tipoVisu: "Pedidos",
    };
  },
  watch: {
    "$props.mesaObj": {
      deep: true,
      handler() {
        this.calc();
      },
    },
    comandaSel: function () {
      // console.log("change1", this.comandaSel);
      this.$forceUpdate();
    },
  },
  computed: {
    pedidos() {
      return this.mesaObj.pedidos
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
        )
        .reduce((ret, vl) => {
          let hasProd = ret.find(
            (x) =>
              x.cod_produto == vl.cod_produto && x.cod_pedido == vl.cod_pedido
          );
          if (!hasProd) {
            ret.push({ ...vl, lst: [vl] });
          } else {
            if (
              !hasProd.lst.some((x) => x.cod_pedido_item == vl.cod_pedido_item)
            ) {
              hasProd.quantidade += vl.quantidade;
              hasProd.vl_total += vl.vl_total;
            }
          }
          return ret;
        }, []);
    },
    pedidosPorPedido() {
      console.log("p", this.mesaObj.pedidos);
      let peds = this.mesaObj.pedidos
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
        )
        .reduce((ret, vl) => {
          let has = ret.find((x) => x.comanda == vl.comanda);
          let it = vl;
          if (!has) {
            ret.push({
              comanda_id: vl.comanda_id,
              comanda: vl.comanda,
              itens: [it],
              qt: ret.length + 1,
              vl: !vl.excluido || vl.excluido == 0 ? vl.vl_total : 0,
              usuario: vl.usuario,
              data: vl.data,
              vl_un: vl.vl_unitario,
              cod_pedido: vl.cod_pedido,
            });
          } else {
            has.itens.push(it);
            if (!vl.excluido || vl.excluido == 0) has.vl += vl.vl_total;
          }
          return ret;
        }, []);

      for (let p of peds) {
        p.itens = p.itens.reduce((ret, vl) => {
          let hasProd = ret.find(
            (x) =>
              x.cod_produto == vl.cod_produto && x.cod_pedido == vl.cod_pedido
          );
          if (!hasProd) {
            ret.push({ ...vl, lst: [vl] });
          } else {
            if (
              !hasProd.lst.some((x) => x.cod_pedido_item == vl.cod_pedido_item)
            )
              hasProd.quantidade += vl.quantidade;
            hasProd.vl_total += vl.vl_total;
          }
          return ret;
        }, []);
      }
      return peds;
    },
  },
  methods: {
    gotoPG(){
      this.$emit("gotoPG")
    },
    getComandaNome(nome, index) {
      if (nome && nome.includes("|")) {
        return nome.split("|")[index];
      } else {
        return nome;
      }
    },
    calc() {
      // this.pedidosPorPedido =
    },
    addPgto() {
      this.$emit("addPgto");
    },
    removeTaxa() {
      console.log("removeTaxa 1");
      this.$emit("removeATaxa");
    },
    imprimeComprovanteSaida(it) {
      console.log("item", it);
      this.$emit("comprovanteSaida", it);

      this.showToast("success", "Impressão de Comprovante de Saida solicitado");
    },
    fecharMesa() {
      if (
        (!this.mesaObj.dt_fechada &&
          this.comandaSel &&
          !this.comandaSel.dt_fechada) ||
        !this.comandaSel
      ) {
        console.log("fechar02");

        this.$emit("fecharMesa");
      }
    },
    openItem(it) {
      if (this.comandaSel && this.comandaSel.dt_fechada) return;
      this.$emit("openPedidoEdit", {
        cod_pedido: it.cod_pedido,
        cod_mesa: it.cod_mesa,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btPlus {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: green;
  color: white;
  border-radius: 50%;
  font-size: 50px;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
}
.containerPedidosResumo {
  width: 100%;
  height: 10%;
  padding-top: 5px;
  font-size: 16px;

  text-align: center;
  margin-top: 5px;
  font-weight: bolder;
}
.containerPedidos {
  overflow: hidden;
  overflow-y: auto;
  //width: 100%;
  //height: 100%;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0px;
  bottom: 01px;
  overflow: auto;

  padding-bottom: 40px;

  display: flex;
  flex-direction: column;
  padding: 5px;
  padding-bottom: 200px;
  .itemCancelado {
    opacity: 0.8;
    color: red !important;
    text-decoration: line-through;
  }
  .item {
    font-size: 12px;
    border-bottom: 1px dotted rgb(122, 122, 122);
    padding-bottom: 3px;
    padding-top: 3px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
    .imgs {
      background-color: #f9f9f9;
      width: 70px;
      height: 70px;
      display: flex;
      flex-flow: row wrap;
      div {
        flex: 1;
        background-size: cover;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50%;
        border-right: 1px solid #d9d9d9;
      }
    }
  }
  .pedido {
    margin-bottom: 5px;

    .item {
      font-size: 12px;
      border-bottom: 1px dotted rgb(122, 122, 122);
      padding-bottom: 3px;
      padding-top: 3px;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      }
    }
  }
  .pedidoCancelado {
    opacity: 0.3;
    color: red;
  }
}
</style>
